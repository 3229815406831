import { Injectable } from '@angular/core';
import { Job, JobDatService } from '../job';
import { Order, OrderItem, OrderJob } from './order-types';
import { firstValueFrom, Observable } from 'rxjs';
import { DateUtilsService } from 'src/app/services/date-utils.service';
import { Timestamp } from 'firebase/firestore';
import firebase from 'firebase/compat/app';

@Injectable({
    providedIn: 'root'
}) 
export class OrderData {
    constructor(
        private jobData: JobDatService,
        private dateUtils: DateUtilsService,
    ) {
    }

    public async orderFromJob(job: Job){
        const orderJobs = await firstValueFrom<Job[]>(this.jobData.getJobsByOrderId$(job.orderId));
        const minJobInOrder = orderJobs.find(cj => cj.id === Math.min(...orderJobs.map(j=>j.id)));

        const order: Order = {
            orderId: minJobInOrder.orderId,
            defaultScheduledDate: minJobInOrder.scheduling.itinerary.date,
            orderNumber: minJobInOrder.customerPOnum || '',
            items: this.getOrderItemsFromJobs(orderJobs, minJobInOrder.id),
            address: minJobInOrder.address,
            customer: minJobInOrder.customer,
            openingTime: minJobInOrder.openingTime,
            closingTime: minJobInOrder.closingTime,
            notes: minJobInOrder.notes,
            vip: minJobInOrder.vip,
            jobs: orderJobs.map(j=>this.getOrderJobFromJob(j, j.id===minJobInOrder.id))
        }
        return order;
    }

    public async jobsFromOrder(order: Order){
        const jobs = [];
        for(let i = 0; i < order.jobs.length; i++) {
            const orderJob = order.jobs[i];
            const job: any = {
                id: orderJob.id,
                orderId: order.orderId,
                customerPOnum: order.orderNumber || '',
                items: await this.getJobItemsFromOrder(order, orderJob.id),
                customer: order.customer,
                opening_time: order.openingTime, 
                closing_time: order.closingTime, 
                service_time: orderJob.serviceTime,
                thirdMan: orderJob.thirdMan,
                notes: order.notes,
                vip: order.vip,
                scheduling:{
                    itinerary:{date: orderJob.scheduledDate},
                    preferences: {
                        time_window: {
                            end: Timestamp.fromDate(this.dateUtils.setTimeToDate(orderJob.preferredEnd, this.dateUtils.dateFromISO(orderJob.scheduledDate))),
                            start: Timestamp.fromDate(this.dateUtils.setTimeToDate(orderJob.preferredStart, this.dateUtils.dateFromISO(orderJob.scheduledDate)))
                        }
                    }
                },
                coordinated: orderJob.coordinated,
                sequence: orderJob.coordinated ? orderJob.sequence : 1
            }
            if(order.address){
                job.address = order.address;
            }
            if(orderJob.deleted){
                job.deleted = true;
            }
            
            jobs.push(job);
        }
        
        
        return jobs;
    }

    public async update(order: Order, addressModified: boolean) {
        if(!order.orderId){
            order.orderId = await this.jobData.getNextOrderId();
        }
        const jobs = await this.jobsFromOrder(order);
        //TODO: if JOB ID <0, create JOB here
        for(const job of jobs) {
            //Update existing job
            if(job.id >= 0) {
                const existingJob = await firstValueFrom<Job>(this.jobData.getJob$(job.id));
                if(job.deleted){
                    await existingJob.delete(job);
                }
                else{
                    if(!addressModified){
                        delete job.address;
                    }
                    if(existingJob.scheduling?.itinerary?.date !== job.scheduling.itinerary?.date){
                        job.carrierId = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.arrivalTimeWindowEnd = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.arrivalTimeWindowStart = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.arrival_eta = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.cumulative_duration = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.distance_from_previous = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.duration_from_previous = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.duration_from_previous_traffic = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.route_id = firebase.firestore.FieldValue.delete();
                        job.scheduling.itinerary.stop_number = firebase.firestore.FieldValue.delete();
                    }
                    await existingJob.update(job);
                }
            }
            //Create new job
            else{
                await this.jobData.insertJob(job);
            }
        }
    }

    private getOrderItemsFromJobs(jobs: Job[], minJobId: number): Order['items']{
        const itemsWithJob = jobs.map(j=>j.items.map(i=>{return {...i, job: j}})).flat();
        return itemsWithJob.reduce((itemsMerged, jobItem) => {
            const jobSubitem = jobItem.type==='S' && jobItem.subItems?.length>0 ? jobItem.subItems[0] : { sku: '', description: '', jobDetailId: 0};
            const item = {
                id: jobSubitem.jobDetailId || 0,
                modelSku: jobSubitem.sku,
                description: jobSubitem.description,
                services: []
            }
            if(!itemsMerged.some(i=>i.modelSku === item.modelSku && i.id === item.id)){
                itemsMerged.push(item);
            }
            item.services = itemsWithJob
                .filter(jobItem=>jobItem.type==='S' && jobItem.subItems?.length>0 && jobItem.subItems[0].sku === item.modelSku && jobItem.subItems[0].jobDetailId === item.id)
                .map(jobItem=>{
                    return {
                        name: jobItem.sku,
                        description: jobItem.description,
                        job: this.getOrderJobFromJob(jobItem.job, jobItem.job.id === minJobId),
                        isInstall: jobItem.installation
                    }
            });
            return itemsMerged;
        }, [])
    }

    private async getJobItemsFromOrder(order: Order, jobId: number): Promise<Job['items']>{
        const items: Job['items'] = []
        const filteredItems = order.items.filter(i=>i.services.some(s=>s.job.id===jobId));
        for (let i = 0; i < filteredItems.length; i++) {
            const item = filteredItems[i];
            let jobDetailId = item.id;
            if(item.id<=0){
                jobDetailId = await this.jobData.getNextJobDetailId();
                item.id = jobDetailId;
            }
            item.services.filter(s=>s.job.id===jobId).forEach(s=>{
                items.push({
                    type: 'S',
                    sku: s.name,
                    description: s.description,
                    quantity: 1,
                    installation: s.isInstall,
                    subItems: [{
                        jobDetailId,
                        sku: item.modelSku,
                        quantity: 1,
                        installation: s.isInstall,
                        type: 'I',
                        description: item.description
                    }]
                });
            })
        }
        return items;
    }

    private getOrderJobFromJob(job: Job, isMain): OrderJob{
        return {
            id: job.id,
            scheduledDate: job.scheduling.itinerary.date,
            serviceTime: job.serviceTime,
            preferredStart: this.dateUtils.formatDate(job.scheduling.preferences.time_window.start.toDate(), 'HH:mm'),
            preferredEnd: this.dateUtils.formatDate(job.scheduling.preferences.time_window.end.toDate(), 'HH:mm'),
            thirdMan: job.thirdMan,
            coordinated: job.coordinated,
            sequence: job.sequence,
            isMain
        }
    }

    public async getSkus(name: string): Promise<Observable<{name: string, description: string}[]>>{
        return await this.jobData.getSkus(name);
    }

}


