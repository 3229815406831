import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { NotificationsComponent } from './components/main-nav/notifications/notifications.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouteDateSelectorModule } from './components/main-nav/route-date-selector/route-date-selector.module';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SlPlanningModule } from './shared/sl-planning/sl-planning.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatChipsModule } from '@angular/material/chips';
import { SlComponentsModule } from './shared/sl-components/sl-components.module';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { from } from 'rxjs';
import { CarriersComponent } from './carriers/carriers.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatGridListModule } from '@angular/material/grid-list'
import { CdkTableModule } from '@angular/cdk/table';
import { CarrierData } from './shared/sl-data/carrier';
import { EditModalComponent } from './carriers/edit-modal/edit-modal.component';
import { CreateUserModalComponent } from './carriers/create-user-modal/create-user-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl } from 'ng-pick-datetime';
import { MatSortModule } from '@angular/material/sort';
import { JobsGridComponent } from './jobs-grid/jobs-grid/jobs-grid.component';
import { OrdersGridComponent } from './orders-grid/orders-grid.component';
import { DxDataGridModule, DxDateBoxModule, DxResponsiveBoxModule } from 'devextreme-angular';
import { UpdateService } from './services/update.service';

@NgModule({
    declarations: [
        AppComponent,
        MainNavComponent,
        NotificationsComponent,
        LoginComponent,
        CarriersComponent,
        EditModalComponent,
        CreateUserModalComponent,
        JobsGridComponent,
        OrdersGridComponent
    ],
    imports: [
        CoreModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutModule,
        RouteDateSelectorModule,
        MatCardModule,
        MatInputModule,
        MatToolbarModule,
        MatButtonModule,
        MatSelectModule,
        MatChipsModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        SlPlanningModule,
        SlComponentsModule,
        MatProgressSpinnerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MatSnackBarModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatGridListModule,
        CdkTableModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        OwlNativeDateTimeModule,
        OwlDateTimeModule,
        MatSortModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxResponsiveBoxModule
    ],
    entryComponents: [],
    providers: [CarrierData, UpdateService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
