import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './core/auth.service';
import { CarriersComponent } from './carriers/carriers.component';
import { JobsGridComponent } from './jobs-grid/jobs-grid/jobs-grid.component';
import { OrdersGridComponent } from './orders-grid/orders-grid.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

const routes: Routes = [
    {
        path: 'planning',
        loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule),
        canActivate: [AuthService],
    },
    {
        path: 'carriers',
        component: CarriersComponent
    },
    {
        path: 'jobs',
        component: JobsGridComponent
    },
    {
        path: 'orders',
        component: OrdersGridComponent
    },
    {
        path: 'forms',
        loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule),
        canActivate: [AuthService],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'customer-delivery-tracking',
        loadChildren: () => import('./customer-site/customer-site.module').then(m => m.CustomerSiteModule)
    },
    {
        path: '**',
        redirectTo: 'planning',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
