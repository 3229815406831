<div>
    <div class="button" (click)="addItem()">Add item</div>
    <mat-accordion multi>
        <mat-expansion-panel *ngFor="let item of (order$ | async)?.items" [hideToggle]="false" togglePosition="before">
            <mat-expansion-panel-header>
                <div class="item-toogle-title">{{item.modelSku}}</div>
                <div class="item-toogle-description">
                    <div class="description">{{item.description}}</div>
                    <div class="buttons">
                        <app-icon name="pencil" class="icon-button" (click)="$event.stopPropagation(); editItem(item)"></app-icon>
                        <app-icon name="close-window-cross" class="icon-button" (click)="$event.stopPropagation(); removeItem(item)"></app-icon>
                    </div>
                </div>
            </mat-expansion-panel-header>

            <div class="panel-content" cdkDropList cdkDropListSortingDisabled [cdkDropListConnectedTo]="listOfDraggableContainerIds$ | async" [id]="''">
                <mat-list>
                    <mat-list-item *ngFor="let service of item.services; let i = index">
                        <div
                            [id]="item.modelSku + '_' + service.name"
                            cdkDrag 
                            [cdkDragData]="service"
                            (cdkDragDropped)="dropped($event, i)"
                            class="order-item-service"
                        >
                            <div class="service-col standard">{{service.name}}</div>
                            <div class="service-col expand">{{service.description}}</div>
                            <div class="service-col small">{{service.job.id}}</div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

</div>