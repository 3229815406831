import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';
import { Job } from '../../sl-data/job';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, switchMap, of } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Item } from '../../sl-data/job/job-type';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateUtilsService } from 'src/app/services/date-utils.service';
import { Timestamp } from 'firebase/firestore';
import { Order } from '../../sl-data/order/order-types';
import { OrderData } from '../../sl-data/order';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const _ = require('lodash');

@Component({
    selector: 'app-edit-order',
    templateUrl: './edit-order.component.html',
    styleUrls: ['./edit-order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditOrderComponent implements OnInit {
    @Input() order$ = new BehaviorSubject<Order>(null);
    @Input() dismissFn: () => void;
    @ViewChild('input', { static: true }) private input: ElementRef<HTMLElement>;
    @ViewChild('addressInput') addressInput: ElementRef;
    
    private addressSearchSubject = new Subject<string>();
    public addressSuggestions$ = new BehaviorSubject<any[]>([]);
    
    public notes: string[] = [];
    public items: any[] = [];
    private orderId: number;
    public saving$ = new BehaviorSubject(false);
    public editForm: FormGroup;
    separatorKeysCodes: number[] = [13, 188];

    constructor(
        private utilities: UtilitiesService,
        public fb: FormBuilder,
        private dateUtils: DateUtilsService,
        private orderData: OrderData,
        private http: HttpClient
    ) {
        // Set up address search
        this.addressSearchSubject.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(query => this.searchAddress(query))
        ).subscribe(results => {
            this.addressSuggestions$.next(results);
        });
    }

    private searchAddress(query: string) {
        if (!query || query.length < 3) return of([]);
        
        const url = `https://api.nextbillion.io/h/geocode?key=${environment.nextbillionApiKey}&q=${encodeURIComponent(query)}&in=countryCode:USA&&at=40.7166638,-74.0`;
        return this.http.get(url, {
            headers: {
            }
        }).pipe(
            switchMap((response: any) => of(response.items || []))
        );
    }

    onAddressInput(event: any) {
        this.addressSearchSubject.next(event.target.value);
    }

    onAddressSelected(event: any) {
        const address = event.option.value;
        const components = address.address;
        
        this.editForm.patchValue({
            streetNumber: components.houseNumber || '',
            streetName: components.street || '',
            city: components.city || '',
            state: components.stateCode || '',
            postalCode: components.postalCode || ''
        });
        
        // Clear the search input
        this.addressInput.nativeElement.value = '';
    }

    @Input() set order(o){
        this.order$.next(o);
    }

    ngOnInit() {
        const order = this.order$.value;
        const defaultDate = this.dateUtils.dateToISO(this.dateUtils.tomorrow());
        const defaultOpeningTime = '07:00';
        const defaultClosingTime = '19:00';
        if(!order.orderId){
            //new order, initialize it:
            order.items = [];
            order.jobs = [];
            order.notes = {};
            order.defaultScheduledDate = defaultDate;
            order.openingTime = defaultOpeningTime;
            order.closingTime = defaultClosingTime;
        }

        this.notes = Object.values(order.notes || {}).map(n=>`${n.internalRef ? n.internalRef + ':' : ''}${n.text ? n.text : ''}`);
        this.editForm = this.fb.group({
            'vip': [order.vip || false, Validators.compose([Validators.required])],
            'firstName': [order.customer?.firstName || '', Validators.compose([Validators.required])],
            'lastName': [order.customer?.lastName || '', Validators.compose([Validators.required])],
            'phone': [order.customer?.phone || '', Validators.compose([Validators.required])],
            'state': [order.address?.state || '', Validators.compose([Validators.required])],
            'city': [order.address?.city || '', Validators.compose([Validators.required])],
            'streetName': [order.address?.streetName || '', Validators.compose([Validators.required])],
            'streetNumber': [order.address?.streetNumber || '', Validators.compose([Validators.required])],
            'apartment': [order.address?.apartment || ''],
            'postalCode': [order.address?.postalCode || ''],
            'orderNumber': [order.orderNumber || ''],
            'defaultScheduledDate': [order.defaultScheduledDate || defaultDate],
            'openingTime': [order.openingTime || defaultOpeningTime],
            'closingTime': [order.closingTime || defaultClosingTime],
          });
          this.items = order.items;
          this.orderId = order.orderId;
          if(order.jobs.length===0){
            //it's a new order, let's create a default job
            order.jobs.push({
                coordinated: false,
                id: -1,
                preferredStart: defaultOpeningTime,
                preferredEnd: defaultClosingTime,
                scheduledDate: defaultDate,
                sequence: 1,
                serviceTime: 30,
                thirdMan: false,
                isMain: true
            });
          }
    }


    private addressIsDirty(){
        const prevAddress = this.order$.value.address;
        return prevAddress?.state !== this.editForm.value.state
        || prevAddress?.city !== this.editForm.value.city
        || prevAddress?.streetName !== this.editForm.value.streetName
        || prevAddress?.streetNumber !== this.editForm.value.streetNumber
        || prevAddress?.apartment !== this.editForm.value.apartment
        || prevAddress?.postalCode !== this.editForm.value.postalCode;
    }

    private getFormatedNotes(): { [key: string]: { id: number; internalRef: string; text: string; }}{
        return this.notes.map((n, i)=>{
            if(n.includes(':')){    
                const [internalRef, text] = n.split(':');
                return {id: i, text, internalRef};
            }
            return {id: i, text: n, internalRef: null};
        }).reduce((acc, n)=>{
            acc[n.id] = n;
            return acc;
        }, {});
    }

    public async okayHandler() {
        try {
            this.saving$.next(true);
            const {
                firstName, lastName, phone, state, city, 
                streetName, streetNumber, apartment, postalCode,
                ...rest } = this.editForm.value;
            const customer = {firstName, lastName, phone};
            const address = {state, city, streetName, streetNumber, apartment, postalCode};

            //const preferredStartDate = this.dateUtils.setTimeToDate(preferredStart, new Date(scheduledDate));
            //const preferredEndDate = this.dateUtils.setTimeToDate(preferredEnd, new Date(scheduledDate));

            const order = {
                ...rest, customer, notes: this.getFormatedNotes(), 
                items: this.order$.value.items, 
                orderId: this.order$.value.orderId,
                jobs: this.order$.value.jobs,
                address
            };
            //Prevent an extra call to recalculate the address if it was not modified
            const addressModified = this.addressIsDirty();

            //update status
            await this.orderData.update(order, addressModified);

            this.utilities.notifySuccess('Order updated successfully.');
            this.dismissFn();
        } 
        catch (error) {
            console.log(error);
            this.utilities.notifyError('Unexpected error updating Order. Please contact system admin');
        }
        finally{
            this.saving$.next(false);
        }
    }
    

    public cancelHandler() {
        this.dismissFn();
    }

    public defaultScheduledDateChanged(event){
        this.order$.value.jobs[0].scheduledDate = event.target.value;
        this.order$.value.defaultScheduledDate = event.target.value;
        this.order$.next(this.order$.value);
    }

    addNote(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            this.notes.push(value);
        }
        event.chipInput!.clear();
    }

    removeNote(note: string, index: number): void {
        this.notes.splice(index, 1);
    }

}
