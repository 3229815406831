import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { ItemService, Order, OrderJob } from '../../sl-data/order/order-types';
import { ItemEditorComponent } from '../item-editor/itemEditor.component';
import { ModalServiceN } from '../../modal/modal.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-item-list',
    templateUrl: './itemList.component.html',
    styleUrls: ['./itemList.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemListComponent {

    public _order$ = new BehaviorSubject<Order>(null);
    
    public listOfDraggableContainerIds$ = this.order$.pipe(map(o => o.jobs.map(j=>`orderJob_${j.id}`)));

    @Input() set order$(order$: BehaviorSubject<Order>){
        this._order$ = order$;
        this.listOfDraggableContainerIds$ = this.order$.pipe(map(o => o.jobs.map(j=>`orderJob_${j.id}`)));
        this._order$.next(this._order$.value);
    }

    get order$(){
        return this._order$;
    }
    
    constructor(
        private modalCtrl: ModalServiceN,
    ) { }

    ngOnInit() {
    }

    public addItem(){
        this.modalCtrl.show(ItemEditorComponent, { order$: this.order$, selectedItem: null });
    }

    public editItem(item){
        this.modalCtrl.show(ItemEditorComponent, { order$: this.order$, selectedItem: item });
    }

    public removeItem(item){
        const order = this.order$.value;
        order.items = order.items.filter(i=>!(i.modelSku===item.modelSku && i.id===item.id));
        this._order$.next(order);
    }

    public async dropped(dropEvent: CdkDragDrop<OrderJob, ItemService>, previousIndex: number) {
        const { container: dropContainer, item: { data } } = dropEvent;
        const service = (data as ItemService);
        const job = (dropContainer.data as OrderJob);
        if(!service || !job){
            return;
        }
        service.job = job;
    }

}
