import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap, map, share, shareReplay } from 'rxjs/operators';
import { Job } from '../../sl-data/job';
import { DateUtilsService } from 'src/app/services/date-utils.service';
import { DetailsModalService } from '../details-view/details-view.component';
import { formatNumber } from '@angular/common';
import { Route } from '../../sl-data/route';
import { CommunicationsData } from '../../sl-data/communications';
import { SlPlanningService } from '../../sl-planning/sl-planning.service';
import { PlanningState } from 'src/app/planning/state';
import { DataEnvService } from '../../sl-data/data-env.service';

@Component({
    selector: 'app-route-cardn',
    templateUrl: './route-card.component.html',
    styleUrls: ['./route-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteCardComponentN {
    private _route$ = new BehaviorSubject<Route>(null);
    public route$ = this._route$.pipe(filter(route => !!route));
    public stopsData = [];
    public routeWithStops$ = this.route$.pipe(
        switchMap(route => route.stops$.pipe(map(stops => {
            route['stops'] = stops;
            this.stopsData = stops;
            return route as Route & { stops: Job[] };
        }))),
        share()
    );
    public collapsed$ = new BehaviorSubject(true);
    public longHovering$ = new BehaviorSubject(false);
    public featureFlags$ = this.dataEnv.featureFlags$;
    private hoveringStartTime = 0;
    
    @HostListener('mouseenter')
    mouseEnter() { this.mousedOver(true); }

    @HostListener('mouseleave')
    mouseLeave() { this.mousedOver(false); }

    public mousedOver(hover: boolean) {
        if (hover && this.routing.getHoveringStop()) {
            //is hovering
            if(!this.hoveringStartTime){
                this.hoveringStartTime = Date.now();
                setTimeout(() => {
                    if(!this.hoveringStartTime){ return; }
                    if (Date.now() - this.hoveringStartTime >= 2000) {
                        this.longHovering$.next(true);
                        this.collapsed$.next(false);
                    }
                }, 2000);
            }
        }
        else{
            //hovering finished
            this.hoveringStartTime = null;
            this.longHovering$.next(false);
            //When mouseout is lost from job card (in partial drag and drop). This compensates that and ensures the hover job dissapears.
            //this.routing.clearHoveringStop();
        }
    }

    @Input() public set route(route: Route) {
        this._route$.next(route);
    }
    @Input() public visible = false;
    @Input() private _selected = false;
    @Input() set selected(selected: boolean) {
        //When the route is selected, make sure it's expanded
        this.collapsed$.next(!selected);
        this._selected = selected;
    }
    public get selected() {
        return this._selected;
    }

    @Input() public interactive = true;
    @Input() set collapsed(collapsed: boolean) {
        this.collapsed$.next(collapsed);
    }
    @Output() visibilityClicked = new EventEmitter();

    constructor(
        private comService: CommunicationsData,
        private dateUtile: DateUtilsService,
        private detailsModal: DetailsModalService,
        private planningService: SlPlanningService,
        private routing: PlanningState,
        private dataEnv: DataEnvService
    ) { }

    public toggleCollapsed() {
        this.collapsed$.next(!this.collapsed$.value);
    }

    public async markShipped() {
        const result = await this.planningService.markAsShipped(this._route$.value.id);
        if (result.errors != "") {
            alert("Error when trying to mark route as shipped. " + result.errors);
        }
    }

    public canMarkShipped$ = (
        this.route$.pipe(
            map(route => {
                if (route.status != 'complete') {
                    return true;
                }
                return false;
            })
        )
    );

    public routeStatusBadge$ = (
        this.route$.pipe(
            map(route => {
                if (route.status === 'started') {
                    return {
                        color: route.color,
                        text: 'LIVE'
                    };
                }
            })
        )
    );

    public routeColor$ = this.route$.pipe(
        map(({ color }) => color)
    );

    public headerTotals$ = (
        this.routeWithStops$.pipe(
            map(route => ({
                large: route.stops.length,
                small: route.stops.reduce((acc, stop) => acc + stop.numberOfPhysicalItems, 0)
            }))
        )
    );

    public anyJobBefore8AM$ = (
        this.routeWithStops$.pipe(
            map(route => route.stops.some(stop=>this.dateUtile.timeToDate(stop.startBefore||'23:59') < this.dateUtile.timeToDate('08:01')))
        )
    );

    public anyJobWithThirdMan$ = (
        this.routeWithStops$.pipe(
            map(route => route.stops.some(stop=>stop.thirdMan))
        )
    );

    public coordinated$ = (
        this.routeWithStops$.pipe(
            map(route => route.stops.some(stop=>stop.coordinated))
        )
    );

    public anyVip$ = (
        this.routeWithStops$.pipe(
            map(route => route.stops.some(stop=>stop.vip))
        )
    );

    public totalRevenue$ = (
        this.routeWithStops$.pipe(
            map(route => route.stops.reduce((totalRevenue, stop) => totalRevenue + stop.revenue, 0))
        )
    );

    public jobs$ = (
        this.routeWithStops$.pipe(
            map(route => route.stops)
        )
    );

    public revenueType$ = (
        this.totalRevenue$.pipe(
            map(total => {
                if(total<=900){
                    return 'bad';
                }
                if(total<=1100){
                    return 'regular';
                }
                return 'good';
            })
        )
    );
    

    // tslint:disable:max-line-length
    public totals$ = (
        this.route$.pipe(
            map(({ endTime, startTime, totalDurationTraffic, totalDistance }) => ([
                `${this.dateUtile.formatDate(startTime, 'h:mm A')} - ${endTime ? this.dateUtile.formatDate(endTime, 'h:mm A') : 'N/A'}`,
                (totalDurationTraffic ? `${this.dateUtile.formatMinutes(totalDurationTraffic, 'h:mm')} hr` : 'N/A'),
                (totalDistance ? `${totalDistance.toFixed(1)} mi` : 'N/A'),
            ]))
        )
    );

    public actuals$ = (
        this.routeWithStops$.pipe(
            map((route) => {
                //console.log(route.status);
                if(!route.status){
                    return null;
                }
                if((!route.driverStatuses)){
                    return null;
                }
                const driverStatusBegin = route.driverStatuses.find(ds=>ds.status==='begin');
                if(!driverStatusBegin){
                    return null;
                }
                if(route.stops.length===0){
                    return null;
                }
                const startTime = this.dateUtile.dateFromISO(driverStatusBegin.time);
                const lastStop = route.stops[route.stops.length-1];
                const finishStatuses = ['completed', 'delivered', 'invoiced', 'not_home', 'partial'];
                if((!finishStatuses.includes(lastStop.status)) || !lastStop.statusTime){
                    return null;
                }
                const endTime = lastStop.statusTime.toDate();
                const durationInMinutes = this.dateUtile.minutesBetween(startTime, endTime);

                const startEnd = `${this.dateUtile.formatDate(startTime, 'h:mm A')} - ${endTime ? this.dateUtile.formatDate(endTime, 'h:mm A') : 'N/A'}`;
                const duration = `${this.dateUtile.formatMinutes(durationInMinutes, 'h:mm')} hr`;

                return {startEnd, duration, distance: ''}
            })
        )
    );

    public statuses$ = (
        this.route$.pipe(
            switchMap(route => route.stops$.pipe(map(stops => ({ stops, route })))),
            map(({ stops, route }) => ([
                {
                    confirmationsIcon: true,
                    route,
                },
                {
                    title: 'Called', color: `#03A524`,
                    amount: stops.reduce((acc, stop) => acc + (stop.scheduling.callCount == null ? 0 : stop.scheduling.callCount), 0)
                },
                {
                    title: 'Responded', color: `#FF8000`,
                    amount: stops.reduce((acc, stop) => acc + (stop.scheduling.callResponseCount == null ? 0 : stop.scheduling.callResponseCount), 0)
                }
            ])
            )
        )
    );

    public async messagesClickHandler() {
        this.detailsModal.show({
            view: 'route',
            route$: this._route$,
            routeTab: 'messages',
        });
    }

    public summaries1$ = (
        of(null).pipe(map(() => {
            const weightCapacityLbs = 15 * 1000 + Math.ceil(Math.random() * 10 * 1000);
            const weightOccupiedLbs = Math.min(Math.ceil(Math.random() * 25 * 1000), weightCapacityLbs);
            const spaceCapacity = 50 + Math.ceil(Math.random() * 100);
            const spaceOccupied = Math.min(spaceCapacity, Math.ceil(Math.random() * 150));
            const availableSpaceThirds = Math.round((spaceOccupied / spaceCapacity) * 3);
            const weightAvailableLbs = Math.round(weightCapacityLbs - weightOccupiedLbs);
            return {
                people: new Array(Math.ceil(Math.random() * 3)),
                installer: this.tossBoolean(),
                truckInfo: {
                    availableSpaceFt: spaceCapacity - spaceOccupied,
                    availableRoom: spaceCapacity,
                    availableWeightLbs: Math.ceil(Math.random() * 100),
                    weightCapacityLbs,
                    weightOccupiedLbs,
                    spaceCapacity,
                    spaceCubes: [...Array(3).keys()].map((v, i) => i < availableSpaceThirds ? 'solid' : 'border').reverse(),
                    weightOccupiedPct: Math.round((weightOccupiedLbs / weightCapacityLbs) * 100),
                    weightCapacityText: (
                        weightAvailableLbs > 0
                            ? `${formatNumber(weightAvailableLbs, 'en-US', '1.0')} lb <br> Available`
                            : `Full`
                    )
                }
            };
        }))
    );

    public summaries$ = (this.route$.pipe(
        map((route: any) => {
            const weightCapacityLbs = route.routeDoc!.max_weight || 0
            let weightOccupiedLbs = 0;
            const spaceCapacity = route.routeDoc!.cubic_feet || 0;
            let spaceOccupied = 0;
            this.stopsData.forEach(row => {
                row.jobDoc.items.forEach(rowItem => {
                    if (rowItem.cube != undefined) {
                        spaceOccupied += rowItem.cube;
                    }

                    if (rowItem.weight != undefined) {
                        weightOccupiedLbs += rowItem.weight;
                    }
                });
            });
            const availableSpaceThirds = Math.round((spaceOccupied / spaceCapacity) * 3);
            const weightAvailableLbs = Math.round(weightCapacityLbs - weightOccupiedLbs);
            return {
                people: route.routeDoc!.number_of_staff || 1,
                installer: route.routeDoc!.installed_on_truck,
                cubic_feet: route.routeDoc!.cubic_feet,
                max_weight: route.routeDoc!.max_weight,
                occupied_weight: route.routeDoc!.occupied_weight,
                truckInfo: {
                    availableSpaceFt: spaceCapacity - spaceOccupied,
                    availableRoom: spaceCapacity,
                    weightAvailableLbs,
                    availableWeightLbs: Math.ceil(Math.random() * 100),
                    weightCapacityLbs,
                    weightOccupiedLbs,
                    spaceCapacity,
                    spaceCubes: [...Array(3).keys()].map((v, i) => i < availableSpaceThirds ? 'solid' : 'border').reverse(),
                    weightOccupiedPct: Math.round((weightOccupiedLbs / weightCapacityLbs) * 100),
                    weightCapacityText: (
                        weightAvailableLbs > 0
                            ? `${formatNumber(weightAvailableLbs, 'en-US', '1.0')} lb <br> Available`
                            : `Full`
                    )
                }
            };
        })
    ));

    private tossBoolean() {
        return Math.ceil(Math.random() * 2) === Math.ceil(Math.random() * 2);
    }

    public formatCustomerName(job: Job){
        const customer = job.customer;
        return `${(customer.firstName == undefined) ? '' : customer.firstName} ${(customer.lastName == undefined) ? '' : customer.lastName}`;
    }

    public formattedAddress = (address: Job['address']) => {
        const { city, state, streetName, streetNumber, apartment } = address;
        return `${streetNumber} ${streetName}`
            + `, ${city}`
            + `, ${state}`
            + (apartment ? ` ${apartment}` : '');
    }

    public dropBetweenJobsMouseEnter(index: number){
        this.routing.setCurrentRouteJobDropPosition(index);
    }
    public dropBetweenJobsMouseLeave(){
        this.routing.clearCurrentRouteJobDropPosition();
    }
}
