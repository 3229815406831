import { CollectionQuery, DataService, WithDocId } from '../data.service';
import { DataPathsService } from '../data-paths.service';
import { switchMap, map, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RouteDataService } from '../route';
import { Injectable } from '@angular/core';
import { CarrierDoc } from './carrier-types';
import { SlServicesService } from '../../sl-services/sl-services.service';
import { PlanningState } from 'src/app/planning/state';
import { DataEnvService } from '../data-env.service';

@Injectable()
export class CarrierData {
    constructor(
        private dataCtrl: DataService,
        private dataPaths: DataPathsService,
        private routeData: RouteDataService,
        private services: SlServicesService,
        private dataEnv: DataEnvService
    ) {

    }
    public carriers$ = combineLatest([this.dataPaths.carriers$, this.dataEnv.selectedHub$])
    .pipe(
        switchMap(([path, selectedHub])=>this.dataCtrl.getCollection$<CarrierDoc>({ 
            path, 
            queries: [selectedHub ? ['hub', '==', selectedHub] : null].filter(Boolean) as CollectionQuery })),
        map(docs => docs.map(doc => new Carrier(doc)))
    );

    public getCarrier$ = (id: string) => {
        return this.carriers$.pipe(map(carriers => carriers.filter(x => x.id === id)[0])
        );
    }

    public getUnassignedCarriers$(date: string = null, currentDateStops$, selectedHub: string) {
        return combineLatest(
            this.routeData.getRoutes$(date, currentDateStops$, selectedHub),
            this.carriers$
        ).pipe(
            map(([routes, carriers]) => {
                return carriers.filter(carrier => !routes.find(route => route.carrierId === carrier.id));
            })
        );
    }

    public async createCarrierUser(carrierId: string, email: string, password: string ) {
        await this.services.callService(`createUser`, { carrierId, email, password });
    }

    public async updateCarrierUser(carrierId: string, userId: string, email: string, password: string ) {
        await this.services.callService(`updateUser`, { carrierId, userId, email, password });
    }

    public async deleteCarrierUser(carrierId: string, userId: string ) {
        await this.services.callService(`deleteUser`, { carrierId, userId });
    }

}

export class Carrier {
    constructor(
        private carrierDoc: WithDocId<CarrierDoc>
    ) { }

    public id = this.carrierDoc.docId;
    public name = this.carrierDoc.name;
    public active = this.carrierDoc.active;
    public virtual = this.carrierDoc.virtual;
    public cubicFeet = this.carrierDoc.cubic_feet;
    public startTime = this.carrierDoc.defaults.startTime;
    public endTime = this.carrierDoc.defaults.endTime;
    public installer = this.carrierDoc.installed_on_truck !== 'false' && this.carrierDoc.installed_on_truck;
    public color = this.carrierDoc.color;
}