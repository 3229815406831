<div>
    <div class="button" (click)="addJob()">Add job</div>
    <mat-list class="order-job-list">
      <mat-list-item
        class="order-job-item"
        *ngFor="let job of (jobs$ | async)">
        <div class="order-job" 
            cdkDropList
            [id]="'orderJob_' + job.id"
            [cdkDropListData]="job"
        >
          <div class="order-job-fields">
            <div class="order-job-id">ID: {{ job.id }}</div>
            <div class="order-job-date">Date: {{ job.scheduledDate }}</div>
            <div class="order-job-time">Service Time: {{ job.serviceTime }}</div>
          </div>
          <div class="buttons">
            <app-icon
              name="pencil"
              class="icon-button"
              (click)="$event.stopPropagation(); editJob(job)"
            ></app-icon>
            <app-icon
              *ngIf="!isMainJob(job)"
              name="close-window-cross"
              class="icon-button"
              (click)="$event.stopPropagation(); removeJob(job)"
            ></app-icon>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>