<div>
    <form [formGroup]="itemForm" class="item-form" (ngSubmit)="okayHandler()">
        <mat-form-field class="full-width" [formGroup]="itemForm">
            <mat-label>SKU</mat-label>
            <input type="text"
                   matInput
                   [formControlName]="'sku'"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" 
                            (optionSelected)="onSkuSelected($event)">
                <mat-option *ngFor="let option of filteredSkus$ | async" [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="itemForm.get('sku').hasError('required')">
                SKU is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
            <mat-label>Description</mat-label>
            <textarea matInput [formControlName]="'description'" rows="3"></textarea>
            <mat-error *ngIf="itemForm.get('description').hasError('required') && itemForm.get('description').touched">
                Description is required.
            </mat-error>
        </mat-form-field>

        <div class="services-section">
            <label>Services:</label>
            <mat-selection-list [formControlName]="'services'">
                <mat-list-option *ngFor="let service of availableServices" [value]="service.value">
                    {{service.name}}
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div class="footer">
            <button mat-raised-button type="button" (click)="cancelHandler()">Cancel</button>
            <button mat-raised-button type="submit" color="primary">Ok</button>
        </div>
    </form>


</div>