<div class="header">
    Update order
</div>
<div class="body" *ngIf="(saving$ | async)" >
    <mat-progress-spinner strokeWidth="5" diameter="50" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="footer" *ngIf="(saving$ | async)" >
    Processing...
</div>
<div class="body" *ngIf="!(saving$ | async)" >
    <form [formGroup]="editForm" (ngSubmit)="okayHandler()" class="form">
        <div class="form-content">
            <div class="wrapper">
                <div class="form-row">
                    <fieldset class="fieldset">
                        <legend class="legend">Customer</legend>
                        <div class="form-row">
                            <mat-form-field class="standard-field">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="firstName" placeholder="" type="text" />
                            </mat-form-field>
                            <mat-form-field class="standard-field">
                                <mat-label>Last Name</mat-label>
                                <input matInput formControlName="lastName" placeholder="" type="text" />
                            </mat-form-field>
                        </div>
                        <div class="form-row">
                            <mat-form-field class="standard-field">
                                <mat-label>Phone</mat-label>
                                <input matInput formControlName="phone" placeholder="" type="text" />
                            </mat-form-field>
                        </div>
                    </fieldset>
                    <fieldset class="fieldset service-info">
                        <legend class="legend">Service Information</legend>
                        <div class="form-row">
                            <mat-form-field class="standard-field">
                                <mat-label for="defaultScheduledDate">Scheduled Date:</mat-label>
                                <input matInput type="date" id="defaultScheduledDate" formControlName="defaultScheduledDate" (change)="defaultScheduledDateChanged($event)">
                            </mat-form-field>
                        </div>
                        <div class="form-row">
                            <mat-form-field class="standard-field">
                                <mat-label>Purchase Order Number</mat-label>
                                <input matInput formControlName="orderNumber" placeholder="" type="text" />
                            </mat-form-field>
                            <mat-form-field class="standard-field">
                                <mat-label>Vip</mat-label>
                                <mat-select formControlName="vip" disableOptionCentering>
                                    <mat-option [value]="true">YES</mat-option>
                                    <mat-option [value]="false">NO</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-row">
                            <mat-form-field class="standard-field">
                                <mat-label>Opening time</mat-label>
                                <input matInput formControlName="openingTime" placeholder="" type="time" style="margin-bottom: -15px;" step="900" />
                            </mat-form-field>
                            <mat-form-field class="standard-field">
                                <mat-label>Closing time</mat-label>
                                <input matInput formControlName="closingTime" placeholder="" type="time" style="margin-bottom: -15px;" step="900" />
                            </mat-form-field>            
                        </div>
                    </fieldset>
                    <fieldset class="fieldset notes">
                        <legend class="legend">Notes</legend>
                        <div class="form-row">
                            <mat-form-field class="notes">
                                <mat-chip-grid #chipGrid aria-label="Note selection">
                                <mat-chip-row *ngFor="let note of notes; let i=index" (removed)="removeNote(note, i)">
                                    <span class="note-chip">{{note}}</span>
                                        <button matChipRemove aria-label="remove note">
                                        <app-icon name="close-window-cross" class="note-chip-icon"></app-icon>
                                        </button>
                                </mat-chip-row>
                                </mat-chip-grid>
                                <input 
                                    [matChipInputFor]="chipGrid"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addNote($event)"
                                    matInput>
                            </mat-form-field>
                        </div>
                    </fieldset>
                </div>
                <div class="form-row">
                    <fieldset class="fieldset address">
                        <legend class="legend">Delivery Address</legend>
                        <div class="form-row">
                            <mat-form-field class="standard-long-field full-width">
                                <mat-label>Search Address</mat-label>
                                <input matInput
                                    #addressInput
                                    type="text"
                                    placeholder="Start typing to search address..."
                                    [matAutocomplete]="auto"
                                    (input)="onAddressInput($event)">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAddressSelected($event)">
                                    <mat-option *ngFor="let address of addressSuggestions$ | async" [value]="address">
                                        {{address.address?.label}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="form-row">
                            <mat-form-field class="standard-field">
                                <mat-label>Street Number</mat-label>
                                <input matInput formControlName="streetNumber" placeholder="" type="text" />
                            </mat-form-field>
                            <mat-form-field class="standard-field">
                                <mat-label>Street Name</mat-label>
                                <input matInput formControlName="streetName" placeholder="" type="text" />
                            </mat-form-field>
                            <mat-form-field class="standard-small-field">
                                <mat-label>State</mat-label>
                                <input matInput formControlName="state" placeholder="" type="text" />
                            </mat-form-field>
                            <mat-form-field class="standard-field">
                                <mat-label>City</mat-label>
                                <input matInput formControlName="city" placeholder="" type="text" />
                            </mat-form-field>
                            <mat-form-field class="standard-field">
                                <mat-label>Postal Code</mat-label>
                                <input matInput formControlName="postalCode" placeholder="" type="text" />
                            </mat-form-field>
                            <mat-form-field class="standard-field">
                                <mat-label>Apartment</mat-label>
                                <input matInput formControlName="apartment" placeholder="" type="text" />
                            </mat-form-field>
                        </div>
                    </fieldset>
                </div>
                <div class="form-row">
                    <fieldset class="fieldset items">
                        <legend class="legend">Items</legend>
                        <app-item-list [order$]="order$"></app-item-list>
                    </fieldset>
                    <fieldset class="fieldset jobs">
                        <legend class="legend">Jobs</legend>
                        <app-order-job-list [order$]="order$"></app-order-job-list>
                    </fieldset>
                </div>
            </div>
        </div>
        <div class="footer" *ngIf="!(saving$ | async)" >
            <button mat-raised-button type="button" (click)="cancelHandler()">Cancel</button>
            <button mat-raised-button type="submit" color="primary">Save</button>
        </div>
    </form>
</div>
