import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { first, groupBy, mergeMap, debounceTime, } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DataEnvService } from '../../shared/sl-data/data-env.service';
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { ModalServiceN } from 'src/app/shared/modal/modal.service';
import { OrderData } from 'src/app/shared/sl-data/order';
import { firstValueFrom } from 'rxjs';
import { Job, JobDatService } from 'src/app/shared/sl-data/job';
import { EditOrderComponent } from 'src/app/shared/sl-components/edit-order/edit-order.component';

@Component({
  selector: 'app-jobs-grid',
  templateUrl: './jobs-grid.component.html',
  styleUrls: ['./jobs-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsGridComponent implements OnInit {
  jobsSource: any = {};
  dataSource: any = {};
  selectedStartDate: Date = new Date();
  selectedEndDate: Date = new Date();
  dataIsChanging: boolean = false;
  public featureFlags$ = this.dataEnv.featureFlags$;

  async onRowClick(e: any) {
    const fflags: any = await firstValueFrom(this.featureFlags$);
    if(!fflags?.editOrder){
      return;
    }
    const job = await firstValueFrom<Job>(this.jobData.getJob$(e.data.docId));
    this.modalCtrl.show(EditOrderComponent, { order: await this.orderData.orderFromJob(job) });
  }

  constructor(
    private fns: AngularFireFunctions,
    private dataEnv: DataEnvService,
    private modalCtrl: ModalServiceN,
    private orderData: OrderData,
    private jobData: JobDatService,
  ) {
    this.jobsSource = new CustomStore({
      key: "docId",
      load: async function (loadOptions: any) {
        return {
          data: [],
        };
      }
    });

    this.dataSource = new DataSource({
      store: this.jobsSource
    });    
  }

  public yesterdayClickHandler() {
    this.dataIsChanging = true;
    const newDate = moment().add(-1, 'days').toDate();
    this.selectedStartDate = newDate;
    this.selectedEndDate = newDate;
    this.dataIsChanging = false;
    this.dataSource.reload();
  }

  public todayClickHandler() {
    this.dataIsChanging = true;
    const newDate = moment().toDate();
    this.selectedStartDate = newDate;
    this.selectedEndDate = newDate;
    this.dataIsChanging = false;
    this.dataSource.reload();
  }

  public tomorrowClickHandler() {
    this.dataIsChanging = true;
    const newDate = moment().add(1, 'days').toDate();
    this.selectedStartDate = newDate;
    this.selectedEndDate = newDate;
    this.dataIsChanging = false;
    this.dataSource.reload();
  }

  public dateChangedStart(e) {
    if (this.dataIsChanging)
      return;
    this.selectedStartDate = e.value;
    this.dataSource.reload();
  }

  public dateChangedEnd(e) {
    if (this.dataIsChanging)
      return;
    this.selectedEndDate = e.value;
    this.dataSource.reload();
  }

  ngOnInit() {

    const currentInstance = this;

    this.jobsSource = new CustomStore({
      key: "docId",
      load: async function (loadOptions: any) {

        //const skip = loadOptions.skip ? loadOptions.skip : 0;
        //const take = loadOptions.take ? loadOptions.take : 0;
        const dateStart = currentInstance.selectedStartDate.toISOString().split('T')[0];
        const dateEnd = currentInstance.selectedStartDate.toISOString().split('T')[0];

        const selectedHub = await currentInstance.dataEnv.selectedHub$.pipe(first()).toPromise() || '';
        const callable = currentInstance.fns.httpsCallable(`queryJobsList/${dateStart}/${dateEnd}/${selectedHub}`);
        return callable({}).toPromise().then((data: any) => {
          data.forEach(job => {
            job.scheduledDate = job.scheduling.itinerary.date;
            job.itemsCount = job.items.length;
            job.customerName = job.customer.firstName + ' ' + job.customer.lastName;
          });

          return {
            data
          };
        });
      }
    });

    this.dataSource = new DataSource({
      store: this.jobsSource
    });    
  }

}

/*
Possible filters:
Date
Status



*/





