import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FieldPath, CollectionReference, Query, Timestamp } from '@firebase/firestore-types';
import { DataPathsService } from './data-paths.service';
import { limitToLast, serverTimestamp } from 'firebase/firestore';

export type WithDocId<T> = T & { docId: string; };
export interface DefaultDocMeta {
    created_at: Timestamp;
    updated_at: Timestamp;
}
export type WithMeatData<T> = T & {
    docId: string;
    updated_at: Date;
    created_at: Date;
};

export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer V>
    ? ReadonlyArray<DeepPartial<V>>
    : DeepPartial<T[P]>
};

export type CollectionQuery = [
    string | FieldPath,
    any,
    any
];

export interface ItineraryCreationInProgDoc {
    date: string;
    type: 'itinerary-creation-in-progress';
}

export interface BatchTransaction<T> {
    path: string;
    data: DeepPartial<T>;
}

@Injectable()
export class DataService {

    constructor(
        private db: AngularFirestore,
        private dataPaths: DataPathsService,
    ) { }

    public getDoc$ = <T>(path: string): Observable<WithMeatData<T>> => {
        return this.db.doc<T & DefaultDocMeta>(path)
            .snapshotChanges()
            .pipe(
                //tap(d => console.log(d.payload.data())),
                map(({ payload }) => ({
                    ...payload.data(),
                    updated_at: (payload.data()?.updated_at || { toDate: () => new Date() }).toDate(),
                    created_at: (payload.data()?.created_at || { toDate: () => new Date() }).toDate(),
                    docId: payload.id,
                }))
            );
    }

    public getDocNoCache$ = <T>(path: string) => {
        return this.db.doc<T & DefaultDocMeta>(path).get();
    }

    public addDoc<T>(path: string, docData: T, docId: string = '') {
        const data = {
            ...docData,
            created_at: serverTimestamp() as Timestamp,
            updated_at: serverTimestamp() as Timestamp,
        };
        let res: any;
        if (docId === '') {
            res = this.db.collection(path)
                .add(data);
        } else {
            res = this.db.collection(path).doc(docId)
                .set(data);
        }
        return res;
    }

    public updateDoc<T>(path: string, docData: DeepPartial<T>) {
        const data = {
            ...docData,
            updated_at: serverTimestamp() as Timestamp,
            routingUpdated_at: serverTimestamp() as Timestamp
        };
        return this.db.doc<DeepPartial<T>>(path)
            .set(data, { merge: true });
    }

    public deleteDoc<T>(path: string) {
        return this.db.doc<DeepPartial<T>>(path)
            .delete();
    }

    public batchUpdateDocuments<T>(transactions: BatchTransaction<T>[]) {
        const batch = this.db.firestore.batch();
        transactions.forEach(({ path, data }) => {
            const updated_at = serverTimestamp() as Timestamp;
            const routingUpdated_at = serverTimestamp() as Timestamp;
            batch.set(this.db.firestore.doc(path), { ...data, updated_at, routingUpdated_at }, { merge: true });
        });
        return batch.commit();
    }

    public getCollection$<T>(params: { path: string, queries?: CollectionQuery[], orderBy?: string, orderDirection?: string, limit?: number, limitToLast?: number }): Observable<WithMeatData<T>[]> {
        const { path, queries = [], orderBy, orderDirection, limit, limitToLast } = params;

        const collectionRef = this.db.collection<T & DefaultDocMeta>(path, ref => {
            var constructedQuery = queries.reduce((rf: CollectionReference | Query, query) => {
                return rf.where(...query);
            }, ref);

            if (orderBy) {
                constructedQuery = constructedQuery.orderBy(orderBy, orderDirection as any);
            }

            if (limit) {
                constructedQuery = constructedQuery.limit(limit);
            }

            if (limitToLast) {
                constructedQuery = constructedQuery.limitToLast(limitToLast);
            }

            return constructedQuery;
        });

        return collectionRef.snapshotChanges()
            .pipe(
                map((changes) => changes.map(({ payload }) => ({
                    ...payload.doc.data(),
                    updated_at: (payload.doc.data().updated_at == undefined ? { toDate: () => new Date() } : payload.doc.data().updated_at || { toDate: () => new Date() }).toDate(),
                    created_at: (payload.doc.data().created_at || { toDate: () => new Date() }).toDate(),
                    docId: payload.doc.id
                }))),
            );
    }

    getCollection(params: { path: any, queries?: CollectionQuery[], limit?: number }) {
        const { path, queries = [], limit } = params;
        const collectionRef = this.db.collection<any>(path, ref => {
            let constructedQuery = queries.reduce((rf: CollectionReference | Query, query) => {
                return rf.where(...query);
            }, ref);

            if (limit) {
                constructedQuery = constructedQuery.limit(limit);
            }

            return constructedQuery;
        });
        return collectionRef.get();
    }


    // All following need to be extracted from this class

    public comIds$ = this.getDoc$<{ coms: string[] }>(this.dataPaths.globalData())
        .pipe(map(({ coms }) => coms));

    public itineraryCreationInProg$(date: string) {
        return this.dataPaths
            .apiStates$
            .pipe(
                switchMap(path => this.getCollection$<ItineraryCreationInProgDoc>({
                    path,
                    queries: [
                        ['type', '==', 'itinerary-creation-in-progress'],
                        ['date', '==', date],
                    ]
                })),
                map(col => !!col[0])
            );
    }
}
