import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, firstValueFrom, from, lastValueFrom, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';  // Add switchMap
import { Order, OrderItem } from '../../sl-data/order/order-types';
import { OrderData } from '../../sl-data/order';

@Component({
  selector: 'app-item-editor',
  templateUrl: './itemEditor.component.html',
  styleUrls: ['./itemEditor.component.scss']
})
export class ItemEditorComponent implements OnInit {
  itemForm: FormGroup;
  filteredSkus$: Observable<any[]>;
  @Input() order$: BehaviorSubject<Order>;
  @Input() selectedItem: OrderItem;
  @Input() dismissFn: () => void;
  
  availableServices = [
    { value: 'DELIVERY', name: 'Delivery' },
    { value: 'INSTALLATION', name: 'Installation' },
    { value: 'ASSEMBLY', name: 'Assembly' },
    { value: 'REMOVAL', name: 'Removal' },
    { value: 'REPAIR', name: 'Repair' },
    { value: 'MAINTENANCE', name: 'Maintenance' },
    { value: 'OTHER', name: 'Other' }

  ];

  constructor(
    private fb: FormBuilder,
    private orderData: OrderData
  ) {
  }

  private filteredSkus : {name: string, description: string}[] = [];
  ngOnInit() {
    console.log(this.order$.value);
    console.log(this.selectedItem);
    this.itemForm = this.fb.group({
        sku: [this.selectedItem?.modelSku || '', Validators.compose([Validators.required])],
        description: [this.selectedItem?.description || '', Validators.compose([Validators.required])],
        services: [this.selectedItem?.services.map(s=>this.availableServices.some(as=> as.value === s.name) ? s.name : 'Other') || []]
      });
  
    // Setup SKU autocomplete with switchMap
    this.filteredSkus$ = this.itemForm.get('sku').valueChanges.pipe(
        startWith(this.itemForm.get('sku').value || ''), // Start with the current value
        switchMap(async value => await this._filterSkus(value)), // Switch to the new observable
        switchMap(result => from(result)),
        tap(filteredSkus => this.filteredSkus =  filteredSkus)
    );
  }

  private async _filterSkus(value: string): Promise<Observable<{name: string, description: string}[]>> {
    const filterValue = value?.toUpperCase() || '';  // Add null check
    return await this.orderData.getSkus(filterValue);
  }

  async onSkuSelected(event: any) {
    const val = event.option.value;
    const item = this.filteredSkus.find(i=>i.name === val);//console.log(item?.description);
    this.itemForm.patchValue({
        description: item?.description
    });
  }

  public okayHandler(){
    console.log('okayHandler');
    if (!this.itemForm.valid) {
        this.itemForm.markAllAsTouched();
        return;
    }
    const {sku, description, services} = this.itemForm.value;
    const jobs = this.order$.value.jobs;
    const minJobInOrder = jobs.find(j=>j.isMain);
    const servicesFormatted = services.map(s=>{
        return {
            name: s,
            description: s.charAt(0).toUpperCase() + s.slice(1).toLowerCase(),
            job: minJobInOrder,
            isInstall: s==='INSTALL'
        }
    });
    if(this.selectedItem){
        this.selectedItem.modelSku = sku;
        this.selectedItem.description = description;
        this.selectedItem.services = servicesFormatted;
    }
    else{
        const items = this.order$.value.items;
        this.order$.value.items.push({
            id: Math.min(...(items.some(j=>j.id<0) ? items.filter(j=>j.id<0).map(j=>j.id) : [0])) - 1,
            modelSku: sku,
            description: description,
            services: servicesFormatted
        });
    }
    this.order$.next(this.order$.value);
    this.dismissFn();

  }

  public cancelHandler() {
    this.dismissFn();
  }
}
