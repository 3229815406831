import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DataEnvService } from '../sl-data/data-env.service';
import { first, groupBy, mergeMap, debounceTime, } from 'rxjs/operators';
import { ReplaySubject, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SlPlanningService {
    private _scheduledRouteUpdates = new ReplaySubject<{ id: string }>();

    constructor(
        private fns: AngularFireFunctions,
        private dataEnv: DataEnvService,
    ) {
        if (window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost')) {
            console.log('using local backend');
            fns.useFunctionsEmulator('http://localhost:5001');
        }

        this._scheduledRouteUpdates.pipe(
            groupBy(rt => rt.id),
            mergeMap(group$ => group$.pipe(debounceTime(1200))),
        ).subscribe(({ id }) => this._updateRouting(id));
    }

    public async warmupFunction(functionName: string){
        const callable = this.fns.httpsCallable(functionName);
        return callable({
            warmup:true
        }).toPromise();
    }

    public async updateRouting(routeId: string) {
        this._scheduledRouteUpdates.next({ id: routeId });
    }

    public async _updateRouting(routeId: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`updateRouteEstimates/${comId}/${routeId}`);
        return callable({}).toPromise();
    }

    public async createItinerary(date: string, optimizationType: 'duration' | 'distance' | 'carriers' = 'carriers', assignToVirtualDrivers: boolean = false, createdBy:string = '', hub?:string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`createItineraries/${comId}/${date}`);
        await firstValueFrom(callable({
            optimizationType,
            assignToVirtualDrivers,
            createdBy,
            hub
        }));
    }

    public async sendTwilioMessage(jobId, message) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`sendTwilioMessage/${comId}/${jobId}`);
        return callable({ message }).toPromise();
    }

    public async dispatcherSetChatMessagesAsReadForJob(jobId: number) {
        const callable = this.fns.httpsCallable(`dispatcherSetChatMessagesAsReadForJob`);
        return callable({ jobId }).toPromise();
    }


    public async optimizeRoute(routeId: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`optimizeRoute/${comId}/${routeId}`);
        return callable({}).toPromise();
    }

    public async applyItinerary(routeId: string, itineraryName: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`applyItinerary/${comId}/${routeId}`);
        return callable({itineraryName}).toPromise();
    }

    public async saveRouteAsItinerary(routeId: string, itineraryName: string, reverse: boolean = false) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`saveRouteAsItinerary/${comId}/${routeId}`);
        return callable({itineraryName, reverse}).toPromise();
    }

    public async removeItineraries(routeId: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`removeItineraries/${comId}/${routeId}`);
        return callable({}).toPromise();
    }

    public async createEmptyRoutes(date: string, carrierId?: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const selectedHub = await this.dataEnv.selectedHub$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`createEmptyRoutes/${comId}/${date}`);
        return callable({carrierId, hub: selectedHub}).toPromise();
    }

    public async changeJobItemDate(routeId: string, itemDetailId: string, jobId: number, newDate: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`changeJobItemDate/${comId}/${routeId}/${itemDetailId}/${jobId}/${newDate}`);
        return callable({}).toPromise();
    }

    public async assignCarrierToRoute(routeId: string, carrierId: string) {
        console.log(`Executed update: ${routeId}`);
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`assignCarrierToRoute/${comId}`);
        return callable({
            carrierId,
            routeId
        }).toPromise();
    }

    public async printBookReport(date: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`printBookReport/${comId}/${date}`);
        return callable({}).toPromise();
    }

    public async printSummary(routeId: string, whereToSend: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`printSummary/${comId}/${routeId}/${encodeURI(whereToSend)}`);
        return callable({}).toPromise();
    }

    public async printJobsReports(routeId: string, whereToSend: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`printJobsReport/${comId}/${routeId}/${encodeURI(whereToSend)}`);
        return callable({}).toPromise();
    }

    public async updateRouteLock(routeId: string, lockStatus: boolean) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`updateRouteLock/${comId}/${routeId}/${lockStatus ? "1" : "0"}`);
        return callable({}).toPromise();
    }

    public async markAsShipped(routeId: string) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`markRouteAsShipped/${comId}/${routeId}`);
        return callable({}).toPromise();
    }

    public async markJobAsShipped(jobId: number) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`changeJobStatus/${comId}/${jobId}/shipped`);
        return callable({}).toPromise();
    }

    public async markJobAsUnpacked(jobId: number) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`changeJobStatus/${comId}/${jobId}/unpacked`);
        return callable({}).toPromise();
    }

    public async markJobAsUnshipped(jobId: number) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`changeJobStatus/${comId}/${jobId}/unshipped`);
        return callable({}).toPromise();
    }

    public async markJobAsInvoiced(jobId: number) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        const callable = this.fns.httpsCallable(`changeJobStatus/${comId}/${jobId}/invoiced`);
        return callable({}).toPromise();
    }

}
