<div class="container">
    <ng-container>
        <div class="line">
            <div class="item">
                <div style="display:table-cell">Start Date:</div>
                <dx-date-box  style="display:table-cell" type="date" [value]="selectedStartDate" (onValueChanged)="dateChangedStart($event)">
                </dx-date-box>
            </div>
            <div class="item">
                <div style="display:table-cell">End Date:</div>
                <dx-date-box style="display:table-cell" type="date" [value]="selectedEndDate" (onValueChanged)="dateChangedEnd($event)">
                </dx-date-box>
            </div>
            <div class="item">
                <button (click)="yesterdayClickHandler()" [innerText]="'Yesterday'"></button>
            </div>
            <div class="item">
                <button (click)="todayClickHandler()" [innerText]="'Today'"></button>
            </div>
            <div class="item">
                <button (click)="tomorrowClickHandler()" [innerText]="'Tomorrow'"></button>
            </div>
            <div class="item">
                <button (click)="createNewOrder()" class="new-order-btn">New Order</button>
            </div>
        </div>

        <dx-data-grid id="dataGrid"
        [dataSource]="dataSource"
        [remoteOperations]="false"
        [showBorders]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        (onRowClick)="onRowClick($event)"
        >
            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
            <dxo-group-panel [visible]="true" [allowColumnDragging]="true"></dxo-group-panel>

            <dxi-column dataField="docId" caption="Id"></dxi-column>
            <dxi-column dataField="orderId" caption="Order" [groupIndex]="0"></dxi-column>
            <dxi-column dataField="companyId" caption="Company"></dxi-column>
            <dxi-column dataField="status" caption="Status"></dxi-column>
            <dxi-column dataField="scheduledDate" caption="Date"></dxi-column>
            <dxi-column dataField="carrierId" caption="CarrierId"></dxi-column>
            <dxi-column dataField="itemsCount" caption="Items"></dxi-column>
            <dxi-column dataField="customerName" caption="Customer"></dxi-column>

            <dxo-scrolling mode="virtual" rowRenderingMode="virtual" ></dxo-scrolling>
            <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>

        </dx-data-grid>
    </ng-container>
  
</div>
