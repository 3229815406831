import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';  // Add switchMap
import { Order, OrderItem, OrderJob } from '../../sl-data/order/order-types';

@Component({
  selector: 'app-order-job-editor',
  templateUrl: './orderJobEditor.component.html',
  styleUrls: ['./orderJobEditor.component.scss']
})
export class OrderJobEditorComponent implements OnInit {
  jobForm: FormGroup;
  @Input() order$: BehaviorSubject<Order>;
  @Input() selectedJob: OrderJob;
  @Input() dismissFn: () => void;
  
  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore
  ) {
  }

  ngOnInit() {
    this.jobForm = this.fb.group({
        serviceTime: [this.selectedJob?.serviceTime || 0, Validators.compose([Validators.required])],
        scheduledDate: [this.selectedJob?.scheduledDate || this.order$.value.defaultScheduledDate || '', Validators.compose([Validators.required])],
        preferredStart: [this.selectedJob?.preferredStart || this.order$.value.openingTime || '', Validators.compose([Validators.required])],
        preferredEnd: [this.selectedJob?.preferredEnd || this.order$.value.closingTime || '', Validators.compose([Validators.required])],
        thirdMan: [this.selectedJob?.thirdMan || false, Validators.compose([Validators.required])],
        coordinated: [this.selectedJob?.coordinated || false, Validators.compose([Validators.required])],
        sequence: [this.selectedJob?.sequence || 0, Validators.compose([Validators.required])],
      });
      
    // Setup SKU autocomplete with switchMap
  }

  public okayHandler(){
    console.log('okayHandler');
    if (!this.jobForm.valid) {
        this.jobForm.markAllAsTouched();
        return;
    }
    const {scheduledDate, serviceTime, coordinated, sequence, thirdMan, preferredStart, preferredEnd} = this.jobForm.value;
    console.log('okayHandler1');
    if(this.selectedJob){
      this.selectedJob.serviceTime = serviceTime;
      this.selectedJob.scheduledDate = scheduledDate;
      this.selectedJob.preferredStart = preferredStart;
      this.selectedJob.preferredEnd = preferredEnd;
      this.selectedJob.thirdMan = thirdMan;
      this.selectedJob.coordinated = coordinated;
      this.selectedJob.sequence = sequence;
    }
    else{
      const order = this.order$.value;
      const newJob: OrderJob = {
        id: Math.min(...(order.jobs.some(j=>j.id<0) ? order.jobs.filter(j=>j.id<0).map(j=>j.id) : [0])) - 1,
        scheduledDate, serviceTime, coordinated, sequence, thirdMan, preferredStart, preferredEnd,
        isMain: false
      }
      order.jobs.push(newJob);
    }
    this.order$.next(this.order$.value);
    this.dismissFn();

  }

  public cancelHandler() {
    this.dismissFn();
  }
}