<div class="route-header" (click)="toggleCollapsed();">
    <div class="collapse-toggle"
        *ngIf="interactive"
        (click)="toggleCollapsed(); $event.stopPropagation()"
        [ngClass]="(collapsed$ | async) ? 'collapsed' : 'expanded'">
        <app-icon name='toggle-down'></app-icon>
    </div>
    <app-truck-info
        [route]="route$ | async"
        [badge]="routeStatusBadge$ | async"
        [solidStyle]="selected"
        [withLock]="interactive"
        [featureFlags]="featureFlags$ | async"
        ></app-truck-info>
    <!--<button *ngIf="(collapsed$ | async) === false && (canMarkShipped$ | async)" mat-button class="button-shipped" (click)="markShipped()">Mark Shipped</button>-->
    <div class="totals" *ngIf="headerTotals$ | async as totals">
        <app-icon *ngIf="anyJobWithThirdMan$ | async" class="third-man" title="Third man required in at least one job in this route" name="person"></app-icon>
        <app-icon *ngIf="anyJobBefore8AM$ | async" class="before-8-am" title="At least one job in this route must be delivered before 8 am" name="clock"></app-icon>
        <app-icon *ngIf="coordinated$ | async" class="coordinated"  title="At least one job in this route is coordinated" name="coordinated"></app-icon>
        <app-icon *ngIf="anyVip$ | async" class="vip"  title="At least one job in this route is for a VIP customer" name="vip"></app-icon>
        <div class="large" [innerText]="totals.large"></div>
        <div class="small" [innerText]="totals.small"></div>
    </div>
</div>
<div class="status-summaries"
    *ngIf="(collapsed$ | async) === false">
    <div class="status-summary" *ngFor="let stat of statuses$ | async" [style.color]="stat.color">
        <app-confirmations-icon
            *ngIf="stat.confirmationsIcon; else status"
                [route]="stat.route"
                [interactive]="interactive"></app-confirmations-icon>
        <ng-template #status>
            <ng-container *ngIf="stat.amount; else emptyStat">
                <div class="status-amount" [innerText]="stat.amount"></div>
                <div class="status-title" [innerText]="stat.title | uppercase"></div>
            </ng-container>
            <ng-template #emptyStat>
                <div class="empty-stat"></div>
            </ng-template>
        </ng-template>
    </div>
</div>
<div class="content"
    [ngClass]="(collapsed$ | async) ? 'collapsed' : ''">
    <div class="totals">
        <div class="total" *ngFor="let total of totals$ | async; let i=index">
            <span *ngIf="i !== 0" class="separator">/</span>
            <span [ngClass]="['text', i == 0 ? 'first' : '']" [innerText]="total"></span>
        </div>
    </div>
    <div class="totals">
        <div class="total" *ngIf="(actuals$ | async) as actuals">
            <span>{{actuals.startEnd}} / {{actuals.duration}} {{actuals.distance ? '/' : ''}} {{actuals.distance}}</span>
        </div>
    </div>
    <div class="jobs-drop-area" [ngStyle]="(longHovering$ | async) ? {'display': 'block' } : {'display': 'none'}">
        <div class="job-drop-placeholded" (mouseenter)="dropBetweenJobsMouseEnter(0)" (mouseleave)="dropBetweenJobsMouseLeave()"></div>
        <div *ngFor="let job of jobs$ | async; let i = index;">
            <div class="job-drop">
                <div class="text" class="job-number" [innerText]="job.stopNumber"></div>
                <div class="address-and-name">
                    <div class="text" class="job-customer-address" [innerText]="formattedAddress(job.address)"></div>
                    <div class="text" class="job-customer-name" [innerText]="formatCustomerName(job)"></div>
                </div>
            </div>
            <div class="job-drop-placeholded" (mouseenter)="dropBetweenJobsMouseEnter(i + 1)" (mouseleave)="dropBetweenJobsMouseLeave()"></div>
        </div>
    </div>
    <div class="chat"
        *ngIf="(collapsed$ | async) === false">
        <app-icon name="message"></app-icon>
        <div class="message" (click)="messagesClickHandler(); $event.stopPropagation()"></div>
    </div>
</div>        
<div class="summaries" *ngIf="summaries$ | async as summaries">
    <div *ngIf="(featureFlags$ | async)?.revenue" class="truck-revenue" [ngClass]="revenueType$ | async" [title]="'Total truck revenue: ' + (totalRevenue$ | async)"><app-icon name="dollar"></app-icon></div>
    <div class="confirmation-status"><app-confirmations-icon *ngIf="(statuses$ | async) as statuses" [route]="statuses[0].route" [interactive]="interactive"></app-confirmations-icon></div>
    <div class="installer" *ngIf="summaries.installer">
        <app-icon name="installation"></app-icon>
        <div class="text">{{summaries.installer}}</div>
    </div>
    <div class="truck-size">
        <app-icon name="truck"></app-icon>
        <div class="space-cubes">
            <div class="cube" *ngFor="let cube of summaries.truckInfo.spaceCubes" [ngClass]="[cube]"></div>
        </div>
        <div class="text">
            {{summaries.truckInfo.weightCapacityLbs | number:'1.0'}} lb
            <br>
            {{summaries.truckInfo.spaceCapacity}} f3
        </div>
    </div>
    <div class="truck-weight">
        <app-donut-chart
            [percent]="summaries.truckInfo.weightOccupiedPct"
            ></app-donut-chart>
        <div class="text" [innerHTML]="summaries.truckInfo.weightCapacityText"></div>
    </div>
</div>
