import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { Order, OrderJob } from '../../sl-data/order/order-types';
import { Job } from '../../sl-data/job';
import { OrderJobEditorComponent } from '../order-job-editor/orderJobEditor.component';
import { ModalServiceN } from '../../modal/modal.service';

@Component({
    selector: 'app-order-job-list',
    templateUrl: './orderJobList.component.html',
    styleUrls: ['./orderJobList.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderJobListComponent {

    private _order$ = new BehaviorSubject<Order>(null);
    public jobs$ = this._order$.pipe(
        map(o=>o?.jobs.filter(j=>!j.deleted))
    );

    @Input() set order$(order$: BehaviorSubject<Order>) {
        this._order$ = order$;
        this.jobs$ = this._order$.pipe(
            map(o=>o?.jobs.filter(j=>!j.deleted))
        );
    }
    get order$(){
        return this._order$;
    }
    
    constructor(
        private modalCtrl: ModalServiceN,
    ) { }

    public addJob(){
        this.modalCtrl.show(OrderJobEditorComponent, { order$: this.order$, selectedJob: null });
    }

    public editJob(job: OrderJob){
        this.modalCtrl.show(OrderJobEditorComponent, { order$: this.order$, selectedJob: job });
    }

    public isMainJob(job: OrderJob): boolean{
        return job.id>=0 && !this.order$.value.jobs.filter(j=>j.id>=0).some(j=>j.id<job.id);
    }

    public removeJob(job: OrderJob){
        const order = this.order$.value;
        if(job.id<0){
            order.jobs = order.jobs.filter(j=>j.id!=job.id);
        }
        else{
            order.jobs.find(j=>j.id===job.id).deleted = true;
        }
        this.order$.next(order);
    }


}
