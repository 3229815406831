<div>
    <form [formGroup]="jobForm" class="job-form" (ngSubmit)="okayHandler()">
        <div class="row">
            <mat-form-field class="standard-field">
                <mat-label for="scheduledDate">Scheduled Date:</mat-label>
                <input matInput type="date" id="scheduledDate" formControlName="scheduledDate">
                <mat-error *ngIf="jobForm.get('scheduledDate').hasError('required')">
                    Scheduled Date is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="standard-field" >
                <mat-label for="serviceTime">Service Time:</mat-label>
                <input matInput type="number" id="serviceTime" formControlName="serviceTime">
                <mat-error *ngIf="jobForm.get('serviceTime').hasError('required')">
                    Service Time is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="standard-field preferred-dates" >
                <mat-label for="preferredStart">Preferred Start Time:</mat-label>
                <input matInput type="time" id="preferredStart" formControlName="preferredStart" step="900">
                <mat-error *ngIf="jobForm.get('preferredStart').hasError('required')">
                    Preferred Start Time is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="standard-field preferred-dates" >
                <mat-label for="preferredEnd">Preferred End Time:</mat-label>
                <input matInput type="time" id="preferredEnd" formControlName="preferredEnd" step="900">
                <mat-error *ngIf="jobForm.get('preferredEnd').hasError('required')">
                    Preferred End Time is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-checkbox class="standard-field" formControlName="thirdMan">Third Man Required</mat-checkbox>
        </div>
        <div class="row">
            <mat-checkbox class="standard-field coordinated" formControlName="coordinated">Coordinated</mat-checkbox>
            <div *ngIf="jobForm.get('coordinated').value" class="coordinated">
                <mat-form-field class="standard-field">
                    <mat-label for="sequence">Sequence:</mat-label>
                    <input matInput type="number" id="sequence" formControlName="sequence">
                    <mat-error *ngIf="jobForm.get('sequence').hasError('required')">
                        Sequence is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="footer">
            <button mat-raised-button type="button" (click)="cancelHandler()">Cancel</button>
            <button mat-raised-button type="submit" color="primary">Ok</button>
        </div>
    </form>
</div>